import React from 'react';
import { CheckoutSuccessWrapper } from '../../components/PurchaseFlow/CheckoutSuccessWrapper';
import { ModalsProvider } from '../../components/PurchaseFlow/NewCheckoutWrapper/providers/ModalsProvider';

export default function CheckoutSuccessPage() {

    // let { orderId } = useParams(); might want to use for orderId

    return <ModalsProvider>
        <CheckoutSuccessWrapper />
    </ModalsProvider>
}
