import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import LoadingContext from '../../context/Loading/Loading';

import { getVenue } from '../../utilities/api';

import { PageLoadingContainer, ViewPackageWrapper } from '../../components';

export default function ViewPackagePage() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    let { id } = useParams();

    const [
        venue,
        setVenue
    ] = useState();

    useEffect(() => {
        showLoading()
        getVenue(id)
            .then((res) => {
                setVenue(res.data?.find((venue) => venue.slug === id))
                hideLoading()
            }).catch((err) => {
                console.error(err)
                hideLoading()
            });
    }, [id]);

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="keywords" content={`Calendar | Buy tickets and find event information for upcoming events by ${venue?.name}`} />
                <title>{`${venue?.name} | Event Calendar`}</title>
                {/* Facebook Meta Tags */}
                <meta property="og:url" content={`${window.location.href}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`${venue?.name} | Event Calendar`} />
                <meta property="og:description" content={`Calendar | Buy tickets and find event information for upcoming events by ${venue?.name}`} />
                <meta property="og:image" content={venue?.image[0]?.url} />
                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content={`${window.location.host}`} />
                <meta property="twitter:url" content={`${window.location.href}`} />
                <meta name="twitter:title" content={`${venue?.name} | Event Calendar`} />
                <meta name="twitter:description" content={`Calendar | Buy tickets and find event information for upcoming events by ${venue?.name}`} />
                <meta name="twitter:image" content={venue?.image[0]?.url} />
            </Helmet>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className='background-gradient py-4'>
                    <ViewPackageWrapper venue={venue} />
                </section>
            )}
        </Fragment>
    );
}
