import React, { useLayoutEffect } from 'react';

import { SeatmapProvider } from '../PurchaseFlow/NewSeatedPurchaseWrapper/providers/SeatmapProvider';
import { TicketsProvider } from '../PurchaseFlow/NewSeatedPurchaseWrapper/providers/TicketsProvider/TicketsProvider';
import { CheckoutProvider } from '../PurchaseFlow/NewSeatedPurchaseWrapper/providers/CheckoutProvider';

import { PackageDetails } from './PackageDetails';

import './viewPackageWrapper.scss';

export default function ViewPackageWrapper() {

    useLayoutEffect(() => {
        const el = document.getElementById('main-container')

        el.classList.add('lg-container')

        return () => {
            el.classList.remove('lg-container')
        }
    }, [])

    return (
        <TicketsProvider>
            <CheckoutProvider>
                <SeatmapProvider>
                    <PackageDetails />
                </SeatmapProvider>
            </CheckoutProvider>
        </TicketsProvider>

    );
}
