import React, { useEffect } from "react";
import "./queingPurchaseModal.scss";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../../../../LoadingContainer/Spinner";
import { getReserveTicketStatus } from "../../../../../../utilities/api";

export default function QueingPurchaseModal({ dialogRef, jobId, event }) {
    const navigate = useNavigate();
    const POLL_INTERVAL = 1000; // 1 second
    const MAX_POLL_TIME = 5 * 60 * 1000; // 5 minutes

    useEffect(() => {
        let pollInterval;
        let timeoutId;

        const pollReservationStatus = async () => {
            try {
                const res = await getReserveTicketStatus({ jobId });
                const data = res.data;
                console.log("Reservation status:", data);

                if (data.status === "completed" || data.status === "failed") {
                    // Clean up polling when we're done
                    if (pollInterval) clearInterval(pollInterval);
                    if (timeoutId) clearTimeout(timeoutId);

                    if (data.status === "completed") {
                        navigate(`/checkout?eventId=${event.uuid}`);
                    } else {
                        // You might want to show an error message to the user here
                        console.log("Reservation failed:", data?.failedReason);
                    }
                }
            } catch (error) {
                console.error("Error polling reservation status:", error);
                if (pollInterval) clearInterval(pollInterval);
                if (timeoutId) clearTimeout(timeoutId);
                // You might want to show an error message to the user here
            }
        };

        if (jobId) {
            // Start polling
            pollInterval = setInterval(pollReservationStatus, POLL_INTERVAL);

            // Set timeout to stop polling
            timeoutId = setTimeout(() => {
                if (pollInterval) {
                    clearInterval(pollInterval);
                    console.log("Polling timed out after 5 minutes");
                    // You might want to show a timeout message to the user here
                }
            }, MAX_POLL_TIME);

            // Initial poll
            pollReservationStatus();
        }

        // Cleanup function
        return () => {
            if (pollInterval) clearInterval(pollInterval);
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [jobId, event?.uuid, navigate]);

    return (
        <dialog ref={dialogRef} className='vjx-queing-modal'>
            <div className='updating-modal-content'>
                <Spinner variant='dark' size={"xl"} />
                <h4>Reserving tickets</h4>
                <p>We’re making sure your selection is available</p>
            </div>
        </dialog>
    );
}
