import React, { useRef, useState, useEffect, useContext } from 'react';
import moment from 'moment';

import LoadingContext from '../../context/Loading/Loading';
import authService from '../../utilities/services/auth.service';

import { getMyEvents, getIncomingTransfers, acceptIncomingTransfers, getGuestList } from '../../utilities/api';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import { PageLoadingContainer } from '../PageLoadingContainer';
import { EmptyContainer } from '../EmptyContainer';
import { SwiperNavigationButtons } from '../SwiperNavigationButtons';
import { MyEventsSlider } from './MyEventsSlider';
import { PackagesList } from './PackagesList';

export default function MyEventsWrapper() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { getUser } = authService;
    const user = getUser().user;

    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    const [
        key,
        setKey
    ] = useState('upcoming');

    const [
        orders,
        setOrders
    ] = useState([]);

    const [
        transfers,
        setTransfers
    ] = useState([]);

    const [isAccepting, setIsAccepting] = useState({
        accepting: false,
        id: null
    })

    const [
        guestLists,
        setGuestLists
    ] = useState([]);

    const [packages, setPackages] = useState([])

    const getMyTickets = () => {
        getMyEvents()
            .then((res) => {
                // filter out past events
                setOrders(res.data?.filter((order) => moment(order.event?.start).isSameOrAfter(moment(), 'day')));
                setIsAccepting({
                    accepting: false,
                    id: null
                })
                getIncomingTransfers()
                    .then((res) => {
                        setTransfers(res.data)
                    }).catch((err) => {
                        console.error(err)
                        hideLoading()
                    })
                getGuestList(encodeURIComponent(user?.phoneNumber))
                    .then((res) => {
                        setGuestLists(res.data)
                        hideLoading()
                    })
                    .catch((err) => {
                        console.error(err)
                        hideLoading()
                    })
            })
            .catch((err) => {
                console.error(err)
                hideLoading();
            });

    };

    useEffect(() => {
        showLoading();
        getMyTickets();
    }, []);

    const acceptTransfer = (transfer) => {
        // save id of transfer 
        setIsAccepting({
            accepting: true,
            id: transfer.id
        })
        let data = {
            transferId: transfer.id
        };
        acceptIncomingTransfers(data).then((res) => {
            getMyTickets()
        }).catch((err) => {
            console.error(err)
            setIsAccepting({
                accepting: false,
                id: null
            })
        });
    };

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className="spacer-xs background-gradient">
                    <div className="section-heading-sm">
                        <h1>My events</h1>
                        <div className="tablet-desktop-only">
                            {key !== 'packages' && (
                                <SwiperNavigationButtons navigationPrevRef={navigationPrevRef} navigationNextRef={navigationNextRef} />
                            )}
                        </div>
                    </div>
                    <Tabs
                        defaultActiveKey="upcoming"
                        variant="pills"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className='nav-pills-default' justify
                    >
                        <Tab eventKey="upcoming" title="Upcoming Events">
                            {(orders.length > 0 || transfers.length > 0 || guestLists.length > 0) ? (
                                <MyEventsSlider navigationPrevRef={navigationPrevRef} navigationNextRef={navigationNextRef} orders={orders} transfers={transfers} acceptTransfer={acceptTransfer}
                                    isAccepting={isAccepting} guestLists={guestLists} />
                            ) : (
                                <EmptyContainer>
                                    <h1>You do not have any events</h1>
                                </EmptyContainer>
                            )}
                        </Tab>
                        <Tab eventKey="packages" title="Packages">
                            {packages.length === 0 ? (
                                <PackagesList />
                            ) : (
                                <EmptyContainer>
                                    <h1>You do not have any packages</h1>
                                </EmptyContainer>
                            )}
                        </Tab>
                    </Tabs>


                </section>
            )}
        </>
    );
}
