import React from 'react';
import { useNavigate } from 'react-router-dom';

import { formatCurrency } from '../../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { LoadingContainer } from '../../LoadingContainer';

import package1 from '../../../assets/package1-ice-titans.png'
import package2 from '../../../assets/package2-ice-titans.png'

import './lists.scss';

export default function PackagesList() {

    const navigate = useNavigate()

    return (
        <Stack as="ul">
            <Card body as="li" className='card-xs card--light item'>
                <Stack direction="horizontal" gap={3}>
                    <Card.Img
                        src={package1}
                        alt={`Cover art for 2024-25 Rocky Mountain Vibes - Half A Season Membership package`}
                        width="100"
                        height="100"
                        className="event-image"
                        style={{ width: '118px', height: '94px' }}
                    />
                    <div className="d-flex-column gap-2 align-items-start">
                        <div>
                            <span className="text-muted caption--uppercase">Sports</span>
                            <p className="normal normal-bold">Full season package</p>
                        </div>
                        <Button className="mt-1" onClick={() => navigate('package/36')}>From {formatCurrency(500)}</Button>
                    </div>
                </Stack>
            </Card>
            <Card body as="li" className='card-xs card--light item'>
                <Stack direction="horizontal" gap={3}>
                    <Card.Img
                        src={package2}
                        alt={`Cover art for package`}
                        width="100"
                        height="100"
                        className="event-image"
                        style={{ width: '118px', height: '94px' }}
                    />
                    <div className="d-flex-column gap-2 align-items-start">
                        <div>
                            <span className="text-muted caption--uppercase">Sports</span>
                            <p className="normal normal-bold">Half season package</p>
                        </div>
                        <Button className="mt-1" onClick={() => navigate('package/36')}>From {formatCurrency(250)}</Button>
                    </div>
                </Stack>
            </Card>
            {/* <LoadingContainer /> */}
        </Stack>
    );
}
