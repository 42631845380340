import React, { createContext, useContext, useRef } from "react";
import SideBottomModal from "./SideBottomModal/SideBottomModal";

// Create the context
const SecondModalContext = createContext(undefined);

export const useSecondModal = () => {
  const context = useContext(SecondModalContext);
  if (!context) {
    throw new Error("Context must be used within a SecondModalProvider");
  }
  return context;
};

// Provide certain type of modal used in the page
export const SecondModalProvider = ({ children }) => {
  const dialogRef = useRef(null);

  const openModal = () => {
    dialogRef.current.showModal();
  };

  const closeModal = () => {
    dialogRef.current.close();
  };

  return (
    <SecondModalContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      {children}
      <SideBottomModal dialogRef={dialogRef} handleClose={closeModal} />
    </SecondModalContext.Provider>
  );
};
