import React from 'react';
import { Link } from 'react-router-dom';

import { timezones } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { PackageEvent } from './../PackageEvent';
import { InfoIcon } from '../InfoIcon';

import './packageEventList.scss'

// reusable component in my package page and package details popup
export default function PackageEventList({ eventPackage, linkTo }) {

    const timezone = timezones(eventPackage?.venue?.timezone)

    const getItem = (event, venue) => {
        if (linkTo) {
            return (
                <Link to={linkTo}>
                    <PackageEvent event={event} venue={venue} timezone={timezone} styles="hover-effect card-list--dark-border">
                        <Button
                            id="details-btn"
                            variant="outline-light"
                            size="sm"
                            className='m-0 d-flex align-items-center gap-2'>
                            <InfoIcon />
                            <span className="tablet-desktop-only">Details</span>
                        </Button>
                    </PackageEvent>
                </Link>
            )
        } else {
            return (
                <PackageEvent event={event} venue={venue} timezone={timezone} />
            )
        }
    }
    return (
        <section id="package-event-list">
            <Card body className="card-xl card-xl--dark">
                <div className="mb-3">
                    <Card.Title as="h5" className="card-title-sm card-title--uppercase mb-2">Events List</Card.Title>
                    <Card.Subtitle>This plan includes 12 events. See the confirmed events and their details below.</Card.Subtitle>
                </div>
                <Stack as="ul" gap={2}>
                    {eventPackage?.allEvents?.map(evtPackage => (
                        <li>
                            {getItem(evtPackage, eventPackage.venue)}
                        </li>
                    ))}
                </Stack>
            </Card>
        </section>
    );
}
