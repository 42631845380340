import React, { useLayoutEffect, useEffect, useState, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';
import { useModals } from '../PurchaseFlow/NewCheckoutWrapper/providers/ModalsProvider';

import { packages } from '../../utilities/helpers';
import { getOrder } from '../../utilities/api';

import Stack from 'react-bootstrap/Stack'
import Card from 'react-bootstrap/Card'

import { PageLoadingContainer } from '../PageLoadingContainer';
import { BackButton } from '../BackButton';
import { PackageEventList } from '../PackageEventList';
import { Event } from './../PurchaseFlow/components/Event';

export default function MyPackageWrapper({ orderId }) {

    const { openModal } = useModals()

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const [order, setOrder] = useState()

    const [
        show,
        setShow
    ] = useState(false);

    const [
        ticketAction,
        setTicketAction
    ] = useState('');

    const [
        ticketStatus,
        setTicketStatus
    ] = useState('');

    const [ticket, setTicket] = useState()

    const [deviceType, setDeviceType] = useState("");

    // useLayoutEffect(() => {
    //     const el = document.querySelector('#main-container');

    //     fullHeightContainer(el);

    //     return () => {
    //         removeFullHeightContainer(el);
    //     };
    // }, []);

    // useEffect(
    //     () => {
    //         showLoading();
    //         getMyOrders();
    //         setDeviceType(userDevice)
    //     },
    //     [
    //         orderId
    //     ]
    // );

    const getMyOrders = () => {
        getOrder(orderId).then((res) => {
            setOrder(res.data)
            hideLoading()
        }).catch((err) => {
            console.error(err)
            hideLoading()
        });
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className="spacer-xs">
                    <div className='d-flex-column flex-lg-row gap-5'>
                        <div className="tablet-desktop-only">
                            <BackButton />
                        </div>
                        <Stack gap={4}>
                            <Event isPackage={true} eventPackage={packages[0]} openModal={() => openModal("Event Information", packages[0], true)} />
                            <Card body className='card-xl card-xl--dark'>
                                <Card.Title>Events & Tickets</Card.Title>
                                {/* change the orderId in link to an orderId you have */}
                                <PackageEventList eventPackage={packages[0]} linkTo={'/event-details/1422-727461-8514'} />
                            </Card>
                        </Stack>
                    </div>
                </section>
            )}
        </>
    );
}
