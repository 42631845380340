import { ModalsProvider } from "./providers/ModalsProvider/ModalsProvider";
import { SeatmapProvider } from "./providers/SeatmapProvider";
import { SecondModalProvider } from "./providers/SecondModalProvider";
import { CheckoutProvider } from "./providers/CheckoutProvider";
import { TicketsProvider } from "./providers/TicketsProvider/TicketsProvider";
import { UnlockProvider } from "./providers/UnlockProvider/UnlockProvider";
import NewSeatedPurchaseContainer from "./NewSeatedPurchaseContainer/NewSeatedPurchaseContainer";
import { useEffect } from "react"

// Console art definition
const consoleArt = `
░█░█░█▀▀░█░░░█░░░█▀█░░░█▀▄░█░█░█▀▄░█▀▀
░█▀█░█▀▀░█░░░█░░░█░█░░░█░█░█░█░█░█░█▀▀
░▀░▀░▀▀▀░▀▀▀░▀▀▀░▀▀▀░░░▀▀░░▀▀▀░▀▀░░▀▀▀

Wanna build the future of ticketing?
Head over to https://blocktickets.xyz/jobs
`;


export function NewSeatedPurchaseWrapper({
    event,
    code,
    offers,
    tickets,
    listings,
    priceRangeMinMax,
    eventTicketLimit,
}) {

    // Console message ( to fuck with the haters )
    useEffect(() => {
        // console.clear();
        // console.log(consoleArt);
    }, []);

    return (
        <TicketsProvider
            offers={offers}
            tickets={tickets}
            listings={listings}
            event={event}
            code={code}
            priceRangeMinMax={priceRangeMinMax}
            eventTicketLimit={eventTicketLimit}
        >
            <CheckoutProvider>
                <UnlockProvider>
                    <SecondModalProvider>
                        <ModalsProvider>
                            <SeatmapProvider>
                                <NewSeatedPurchaseContainer />
                            </SeatmapProvider>
                        </ModalsProvider>
                    </SecondModalProvider>
                </UnlockProvider>
            </CheckoutProvider>
        </TicketsProvider>
    );
}
