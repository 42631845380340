import React from 'react';
import { useParams } from 'react-router-dom';

import { ModalsProvider } from '../../components/PurchaseFlow/NewCheckoutWrapper/providers/ModalsProvider';

import { EventDetailsWrapper } from '../../components';

export default function EventDetailsPage() {

    let { orderId } = useParams();

    return <ModalsProvider>
        <EventDetailsWrapper orderId={orderId} />
    </ModalsProvider>;
}
