import React, {
    Suspense,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
    lazy,
} from "react";
import { CenterBottomModal } from "../../components/CenterBottomModal";

const LazyEventInformation = lazy(() =>
    import("../../components/EventInformation/EventInformation")
);

const LazyTicketInformation = lazy(() =>
    import("../../components/TicketInformation/TicketInformation")
);

const LazyLimitedAvailability = lazy(() =>
    import("../components/LimitedAvailability")
);

const LazyReminderModal = lazy(() =>
    import("../components/PaymentMethod/Checkout/CheckoutModal/ReminderModal")
);

const LazyTimeoutModal = lazy(() =>
    import("../components/PaymentMethod/Checkout/CheckoutModal/TimeoutModal")
);

const LazyLeaveModal = lazy(() =>
    import("../components/PaymentMethod/Checkout/CheckoutModal/LeaveModal/LeaveModal")
);

const LazyCardDeclineModal = lazy(() =>
    import("../components/PaymentMethod/Checkout/CheckoutModal/CardDeclineModal")
);

// Create the context
const ModalsContext = createContext(undefined);

export const useModals = () => {
    const context = useContext(ModalsContext);
    if (!context) {
        throw new Error("Context must be used within a ModalsProvider");
    }
    return context;
};

// Provide certain type of modals used in the page
export const ModalsProvider = ({ children }) => {
    const dialogRef = useRef(null);
    const [modalType, setModalType] = useState(null);
    const [CDBBlurred, setCDBBlurred] = useState(false);
    const [modalsData, setModalsData] = useState(null);
    const [isPackage, setIsPackage] = useState(false);

    // Modal has different bg color + mobile padding
    // In these 2 cases
    useEffect(() => {
        const shouldBlur = ["Event Information"].includes(modalType);
        setCDBBlurred(shouldBlur);
    }, [modalType]);

    const openModal = (type, data, isPackage = false) => {
        console.log('OPEN MODAL', type, data, isPackage);
        setModalType(type);
        setModalsData(data); // This can be either event or ticketInformation
        // TODO: get isPackage from data if this is in event ?
        setIsPackage(isPackage)
        dialogRef.current.showModal();
    };

    const closeModal = () => {
        setModalType(null);
        dialogRef.current.close();
    };

    const modalComponents = {
        "Event Information": () => <LazyEventInformation event={modalsData} isPackage={isPackage} />,
        "Ticket Information": () => <LazyTicketInformation cart={modalsData} />,
        "Limited Availability": () => (
            <LazyLimitedAvailability errorShow={modalsData} />
        ),
        "Are you sure?": () => <LazyLeaveModal event={modalsData} handleClose={closeModal} />,
        reminder: () => <LazyReminderModal />,
        timeout: () => <LazyTimeoutModal />,
        declined: () => <LazyCardDeclineModal />,
    };

    // Get the component based on the current modal type
    const ModalContent = modalType ? modalComponents[modalType] : null;

    return (
        <ModalsContext.Provider
            value={{
                openModal,
            }}
        >
            <CenterBottomModal
                dialogRef={dialogRef}
                handleClose={closeModal}
                title={modalType}
                blurred={CDBBlurred}
            >
                <Suspense fallback={null}>{ModalContent && <ModalContent />}</Suspense>
            </CenterBottomModal>
            {children}
        </ModalsContext.Provider>
    );
};
