import React, {
  useState,
  useRef,
  Suspense,
  lazy,
  useEffect,
  useMemo,
} from "react";

import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";

import "./filterBar.scss";
import { useTickets } from "../../../providers/TicketsProvider/TicketsProvider";
import { useModals } from "../../../providers/ModalsProvider/ModalsProvider";
import { useUnlock } from "../../../providers/UnlockProvider/UnlockProvider";
import QuantityFilter from "../../../../components/QuantityFilter/QuantityFilter";
import PriceSort from "../../../../components/PriceSort/PriceSort";

// These might never be opened, dont waste resources on rendering, unless
const LazyPriceFilterCard = lazy(() =>
  import("../../../../components/PriceFilterCard/PriceFilterCard")
);

export default function FilterBar({ isScheduled }) {
  const parentRef = useRef(null);
  const {
    offers,
    eventTicketLimit,
    quickpicks,
    isLoadingTickets,
    updateSearchParam,
    quantity,
    priceRange,
    selectedOfferIds,
    accessCodes,
    accessible,
    sort,
  } = useTickets();
  const { openCBModal } = useModals();
  const { openUnlockModal } = useUnlock();
  const [closeFromAbove, setCloseFromAbove] = useState(false);

  const toggleOffer = (offerId) => {
    let offerIdsCopy = selectedOfferIds;
    if (offerIdsCopy.includes(offerId)) {
      offerIdsCopy = selectedOfferIds.filter((id) => id !== offerId);
    } else {
      offerIdsCopy = [...selectedOfferIds, offerId];
    }

    const offerIdsString = offerIdsCopy.join(",");
    updateSearchParam("offers", offerIdsString);
  };

  const toggleAccessible = (toggle) => {
    updateSearchParam("accessible", toggle);
  };

  // should have .unlocked property
  // accessCode on client side -> UNSAFE
  const isLockedOfferUnlocked = (offer) => {
    const activeOfferItem = accessCodes.find((item) =>
      item.startsWith(offer.id.toString())
    );
    return (
      activeOfferItem &&
      activeOfferItem.split(":")[1].toLowerCase() ===
        offer.accessCode.toLowerCase()
    );
  };

  return (
    <div className={`pb-3`}>
      <div ref={parentRef} className='pb-3'>
        <div className='action-inner'>
          <div className='actions-bar vjxab-pr'>
            <DesktopDropdownMobileButton
              handleChange={(eventKey) =>
                updateSearchParam("quantity", eventKey)
              }
              title={quantity + ` ${quantity > 1 ? "tickets" : "ticket"}`}
              variantLink={false}
              onButtonClick={() => openCBModal("Ticket Quantity")}
            >
              <div className='abcfloating-card'>
                <QuantityFilter
                  range={{
                    min: 1,
                    max: eventTicketLimit ? eventTicketLimit : 50,
                  }}
                  style='actions-bar--vertical greyScale'
                  btnStyle='btn-toggle--no-borders'
                  handleChange={(e) =>
                    updateSearchParam("quantity", e.target.value)
                  }
                  dropdown={true}
                />
              </div>
            </DesktopDropdownMobileButton>
            {/* <DesktopDropdownMobileButton
              handleChange={undefined}
              title={`$${priceRange[0]}-$${priceRange[1]}`}
              variantLink={false}
              onButtonClick={() => openCBModal("Price Range")}
              closeFromAbove={closeFromAbove}
              setCloseFromAbove={setCloseFromAbove}
            >
              <div className='abcd-floating-card'>
                <LazyPriceFilterCard
                  closeModal={() => setCloseFromAbove(true)}
                />
              </div>
            </DesktopDropdownMobileButton> */}
            {offers.map((offer, i) => {
              if (offer.accessCode !== null) {
                // Locked offer
                return (
                  <button
                    key={i}
                    className={`vjx-fb-icon-button vjx-fb-bt-styling ${
                      isLockedOfferUnlocked(offer) &&
                      selectedOfferIds.includes(offer.id)
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      if (isLockedOfferUnlocked(offer)) {
                        console.log("Attempting to toggle offer:", offer.id);
                        toggleOffer(offer.id);
                      } else {
                        console.log(
                          "Opening unlock modal for offer:",
                          offer.id
                        );
                        openUnlockModal(offer);
                      }
                    }}
                  >
                    {isLockedOfferUnlocked(offer) ? (
                      <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M10.788 6.01696L10 3.75016L9.2121 6.01696C8.75434 7.33386 7.52537 8.22676 6.13147 8.25517L3.73213 8.30407L5.6445 9.75392C6.75549 10.5962 7.22492 12.041 6.82119 13.3754L6.12626 15.6724L8.0961 14.3017C9.24049 13.5054 10.7596 13.5054 11.904 14.3017L13.8738 15.6724L13.1789 13.3754C12.7752 12.041 13.2446 10.5962 14.3556 9.75392L16.268 8.30406L13.8686 8.25517C12.4747 8.22676 11.2457 7.33386 10.788 6.01696ZM11.5743 3.20295C11.0555 1.71034 8.94459 1.71035 8.42576 3.20295L7.63783 5.46974C7.40895 6.1282 6.79446 6.57465 6.09751 6.58885L3.69817 6.63774C2.1183 6.66994 1.466 8.67752 2.72522 9.63219L4.63759 11.082C5.19308 11.5032 5.42779 12.2256 5.22593 12.8928L4.531 15.1898C4.07341 16.7023 5.78116 17.9431 7.07823 17.0405L9.04807 15.6697C9.62026 15.2716 10.3798 15.2716 10.952 15.6697L12.9219 17.0405C14.2189 17.9431 15.9267 16.7023 15.4691 15.1898L14.7741 12.8928C14.5723 12.2256 14.807 11.5032 15.3625 11.082L17.2749 9.63219C18.5341 8.67751 17.8818 6.66994 16.3019 6.63774L13.9026 6.58885C13.2056 6.57465 12.5911 6.1282 12.3623 5.46974L11.5743 3.20295Z'
                        fill='#777E91'
                      />
                    </svg>
                    ) : (
                      <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M10 9.16667C8.52285 9.16667 7.20703 9.22047 6.13198 9.2888C5.11226 9.35362 4.32816 10.1316 4.25498 11.1442C4.20292 11.8644 4.16667 12.6234 4.16667 13.3333C4.16667 14.0433 4.20292 14.8022 4.25498 15.5225C4.32816 16.535 5.11226 17.313 6.13198 17.3779C7.20703 17.4462 8.52285 17.5 10 17.5C11.4772 17.5 12.793 17.4462 13.868 17.3779C14.8877 17.313 15.6718 16.535 15.745 15.5225C15.7971 14.8022 15.8333 14.0433 15.8333 13.3333C15.8333 12.6234 15.7971 11.8644 15.745 11.1442C15.6718 10.1316 14.8877 9.35362 13.868 9.2888C12.793 9.22047 11.4772 9.16667 10 9.16667ZM6.02625 7.62549C4.17813 7.74297 2.72614 9.17698 2.59265 11.024C2.53876 11.7695 2.5 12.5708 2.5 13.3333C2.5 14.0959 2.53876 14.8972 2.59265 15.6427C2.72614 17.4897 4.17813 18.9237 6.02625 19.0412C7.13148 19.1114 8.48288 19.1667 10 19.1667C11.5171 19.1667 12.8685 19.1114 13.9737 19.0412C15.8219 18.9237 17.2739 17.4897 17.4074 15.6427C17.4612 14.8972 17.5 14.0959 17.5 13.3333C17.5 12.5708 17.4612 11.7695 17.4074 11.024C17.2739 9.17698 15.8219 7.74297 13.9737 7.62549C12.8685 7.55524 11.5171 7.5 10 7.5C8.48288 7.5 7.13148 7.55524 6.02625 7.62549Z'
                        fill='#777E91'
                      />
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M10.834 13.9439C11.3322 13.6557 11.6673 13.1171 11.6673 12.5002C11.6673 11.5797 10.9211 10.8335 10.0007 10.8335C9.08018 10.8335 8.33398 11.5797 8.33398 12.5002C8.33398 13.1171 8.66915 13.6557 9.16732 13.9439V15.0002C9.16732 15.4604 9.54041 15.8335 10.0007 15.8335C10.4609 15.8335 10.834 15.4604 10.834 15.0002V13.9439Z'
                        fill='#777E91'
                      />
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M5.83398 5.00016C5.83398 2.69898 7.69946 0.833496 10.0007 0.833496C12.3018 0.833496 14.1673 2.69898 14.1673 5.00016V8.3335C14.1673 8.79373 13.7942 9.16683 13.334 9.16683C12.8737 9.16683 12.5007 8.79373 12.5007 8.3335V5.00016C12.5007 3.61945 11.3814 2.50016 10.0007 2.50016C8.61994 2.50016 7.50065 3.61945 7.50065 5.00016V8.3335C7.50065 8.79373 7.12756 9.16683 6.66732 9.16683C6.20708 9.16683 5.83398 8.79373 5.83398 8.3335V5.00016Z'
                        fill='#777E91'
                      />
                    </svg>
                    )}
                    {offer.name}
                  </button>
                );
              }
              return (
                <button
                  key={i}
                  className={`vjx-fb-icon-button vjx-fb-bt-styling  ${
                    selectedOfferIds.includes(offer.id) && "active"
                  }`}
                  onClick={() => toggleOffer(offer.id)}
                >
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M10.788 6.01696L10 3.75016L9.2121 6.01696C8.75434 7.33386 7.52537 8.22676 6.13147 8.25517L3.73213 8.30407L5.6445 9.75392C6.75549 10.5962 7.22492 12.041 6.82119 13.3754L6.12626 15.6724L8.0961 14.3017C9.24049 13.5054 10.7596 13.5054 11.904 14.3017L13.8738 15.6724L13.1789 13.3754C12.7752 12.041 13.2446 10.5962 14.3556 9.75392L16.268 8.30406L13.8686 8.25517C12.4747 8.22676 11.2457 7.33386 10.788 6.01696ZM11.5743 3.20295C11.0555 1.71034 8.94459 1.71035 8.42576 3.20295L7.63783 5.46974C7.40895 6.1282 6.79446 6.57465 6.09751 6.58885L3.69817 6.63774C2.1183 6.66994 1.466 8.67752 2.72522 9.63219L4.63759 11.082C5.19308 11.5032 5.42779 12.2256 5.22593 12.8928L4.531 15.1898C4.07341 16.7023 5.78116 17.9431 7.07823 17.0405L9.04807 15.6697C9.62026 15.2716 10.3798 15.2716 10.952 15.6697L12.9219 17.0405C14.2189 17.9431 15.9267 16.7023 15.4691 15.1898L14.7741 12.8928C14.5723 12.2256 14.807 11.5032 15.3625 11.082L17.2749 9.63219C18.5341 8.67751 17.8818 6.66994 16.3019 6.63774L13.9026 6.58885C13.2056 6.57465 12.5911 6.1282 12.3623 5.46974L11.5743 3.20295Z'
                      fill='#777E91'
                    />
                  </svg>
                  {offer.name}
                </button>
              );
            })}
            <AccessibilityFilter
              toggleAccessible={toggleAccessible}
              accessible={accessible}
            />
          </div>
        </div>
      </div>
      {!isScheduled && (
        <div className={`split-row pxbbb ${isLoadingTickets ? "d-none" : ""}`}>
          <span className='small small-bold'>
            {quickpicks?.length || 0} Listings
          </span>
          <DesktopDropdownMobileButton
            title={"Sort by " + (sort === "bestseat" ? "best seat" : "price")} // for now
            variantLink={true}
            onButtonClick={() => openCBModal("Sort by")}
            handleChange={(eventKey) => updateSearchParam("sort", eventKey)}
          >
            <div className='abcfloating-card'>
              <PriceSort
                dropdown={true}
                btnStyle='btn-toggle--no-borders'
                style='actions-bar--vertical greyScale'
                handleChange={(e) => updateSearchParam("sort", e.target.value)}
              />
            </div>
          </DesktopDropdownMobileButton>
        </div>
      )}
    </div>
  );
}

function AccessibilityFilter({ toggleAccessible, accessible }) {
  return (
    <div className='accessibility-filter'>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.54277 3.6482C8.50477 3.6482 9.27877 2.8662 9.27877 1.9242C9.27877 0.968195 8.50477 0.200195 7.54277 0.200195C6.58477 0.200195 5.80677 0.968195 5.80677 1.9242C5.80677 2.8662 6.58477 3.6482 7.54277 3.6482ZM17.7308 15.4422L14.7708 10.3682C14.5868 10.0622 14.2648 9.8902 13.9308 9.8802H9.58676L9.54277 8.3542H12.6968C13.1268 8.3282 13.4808 8.0042 13.4808 7.5662C13.4808 7.1342 13.1368 6.7982 12.6968 6.7842H9.45476L9.34277 5.5282C9.27877 4.6242 8.49476 3.9122 7.57477 3.9622C6.64677 4.0142 5.94677 4.8062 5.99077 5.7222L6.29676 10.9542C6.37277 11.8722 7.14476 12.5222 8.06076 12.5222H13.6908L15.9908 16.4762C16.2668 16.9242 16.9048 17.1082 17.3848 16.8222C17.8588 16.5342 18.0008 15.9422 17.7308 15.4422ZM8.65877 18.4442C5.81077 18.4442 3.50077 16.1562 3.50077 13.3242C3.50077 11.7742 4.20877 10.3902 5.31077 9.4482L5.21477 7.8482C3.37077 8.9922 2.13477 11.0022 2.13477 13.3242C2.13477 16.9002 5.05277 19.8022 8.65877 19.8022C11.3128 19.8022 13.5808 18.2162 14.6008 15.9602L13.6848 14.3802C13.1928 16.6942 11.1368 18.4442 8.65877 18.4442Z'
          fill='#777E91'
        />
      </svg>
      <Form.Check
        type='switch'
        id={`accessibility-switch`}
        name={"accessibility-switch"}
        // label={label}
        checked={accessible}
        onChange={(e) => toggleAccessible(e.target.checked)}
      />
    </div>
  );
}

function DesktopDropdownMobileButton({
  handleChange,
  title,
  variantLink,
  onButtonClick,
  children,
  setCloseFromAbove,
  closeFromAbove = false,
}) {
  useEffect(() => {
    if (closeFromAbove) {
      handleToggle(false);
    }
  }, [closeFromAbove]);

  const [isOpen, setIsOpen] = useState(false);

  // Toggle dropdown open state
  const handleToggle = (isOpen) => {
    setIsOpen(isOpen);
    if (setCloseFromAbove) {
      setCloseFromAbove(false);
    }
  };

  return (
    <Dropdown
      onSelect={handleChange}
      style={{ position: "unset" }}
      onToggle={handleToggle}
      show={isOpen}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        id='dropdown-custom-components1'
        active={isOpen}
        variantLink={variantLink}
        onButtonClick={onButtonClick}
      >
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu}>
        <Suspense fallback={null}>{children}</Suspense>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const CustomToggle = React.forwardRef(
  ({ children, onClick, active, variantLink, onButtonClick }, ref) => (
    <button
      ref={ref}
      className={
        variantLink
          ? `abcsort-btn mt-0 ${active && "active"}`
          : `mt-0 vjx-fb-icon-button vjx-fb-bt-styling`
      }
      onClick={(e) => {
        e.preventDefault();

        if (window.innerWidth > 768) {
          onClick(e);
        } else {
          // Open CBModal
          onButtonClick();
        }
      }}
    >
      {children}
    </button>
  )
);

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div className='list-unstyled'>{children}</div>
      </div>
    );
  }
);
