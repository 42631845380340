import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment'

import { packages, formatDateTime, timezones, getTimezoneDate } from '../../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';

import package1 from '../../../assets/package1-ice-titans.png'

export default function PackagesList() {

    let timezone = timezones(packages[0]?.venue?.timezone);

    return (
        <Stack gap={3} as="ul">
            <Link to='packages/45'>
                <Card as="li" className="card--light card-xs">
                    <Card.Body className='d-flex-column flex-md-row gap-3'>
                        <div className="card-img-container card-img-container-sm">
                            <Card.Img
                                src={package1}
                                alt="Rocky Mountain Vibes Logo"
                                width="78"
                                height="78"
                            />
                        </div>
                        <Stack gap={2}>
                            <Card.Title as="h5" className="card-title-sm m-0">Full Season Package</Card.Title>
                            <Card.Subtitle as="h6" className="text-secondary card-subtitle-lg">Blocktickets Arena</Card.Subtitle>
                            <Card.Text className="small">From <span>{formatDateTime(getTimezoneDate(packages[0]?.allEvents[0]?.start, timezone), 'seoDate')}</span>,  {" "}<span>{formatDateTime(getTimezoneDate(packages[0]?.allEvents[0]?.start, timezone), 'timeOnly')}</span></Card.Text>
                            <span className='num-packages'>40 events</span>
                        </Stack>
                    </Card.Body>
                </Card>
            </Link>
        </Stack>
    );
}
