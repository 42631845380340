import React from 'react';

import { useSeatmap } from '../../PurchaseFlow/NewSeatedPurchaseWrapper/providers/SeatmapProvider';

import { formatCurrency, packages, timezones } from '../../../utilities/helpers';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { BackButton } from '../../BackButton';
import { PackageEvent } from '../../PackageEvent';

import package1 from '../../../assets/package1-ice-titans.png'

export default function PackageDetails() {

    const { showSeatmap } = useSeatmap()

    let timezone = timezones(packages[0]?.venue?.timezone);

    return (
        <Row id="view-package">
            <Col>
                <Card className='about-card card-xl card-xl--dark full-height'>
                    <Card.Body className="d-flex-column align-items-start gap-4">
                        <BackButton marginBottom={'0'} />
                        <div className='d-flex-column gap-4'>
                            <div className="card-img-container">
                                <Card.Img src={package1} width="114" height="114" alt='Rocky Mountain Vibes Logo' />
                            </div>
                            <div className='d-flex-column gap-3'>
                                <Card.Subtitle as="h6">Ice Titans</Card.Subtitle>
                                <Card.Title as="h5" className='m-0'>Full Season Package</Card.Title>
                                <Card.Text className='location text-secondary'>Blocktickets Arena</Card.Text>
                                <Card.Text>Experience the ultimate hockey season with the Ice Titans' Full Season Package! This exclusive package includes tickets to all 40 home games, ensuring you never miss a moment of the action. Cheer on the Ice Titans as they battle for glory, all while enjoying the best seats in the arena.
                                </Card.Text>
                                <Card.Text><span className='pb-4 mt-2 d-block'>Package Benefits</span>
                                    <ul>
                                        <li>40 games of thrilling hockey action</li>
                                        <li>Priority seating with the best views of the ice</li>
                                        <li>Exclusive Ice Titans merchandise discounts</li>
                                        <li>Access to special fan events and meet-and-greets</li>
                                        <li>Dedicated customer support for package holders</li>
                                    </ul>
                                </Card.Text>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card body className='card-xl card-xl--dark full-height'>
                    <Stack gap={4} className='full-height'>
                        <div className='seat-quantity align-items-center gap-4 justify-content-between'>
                            <Stack>
                                <Card.Title as="h5" className='card-title-sm'>Seat quantity</Card.Title>
                                <p className='small'>Select number of seats per event, you will be seated together.</p>
                            </Stack>
                            <Form.Select aria-label="Seat quantity">
                                <option value="2">2 Ticket</option>
                                <option value="1">1 Ticket</option>
                                <option value="3">3 Ticket</option>
                            </Form.Select>
                        </div>
                        <div className='events-list d-flex-column gap-4 justify-content-between flex-grow-1'>
                            <Card.Title as="h5" className='card-title-sm m-0'>Events list</Card.Title>
                            <Stack as="ul" gap={2}>
                                {packages[0]?.allEvents?.map((event) => (
                                    <li>
                                        <PackageEvent event={event} venue={packages[0]?.venue} timezone={timezone} />
                                    </li>
                                ))}
                            </Stack>
                        </div>
                        <div className='gap-2 d-flex-column flex-md-row align-items-md-center mt-auto justify-content-md-between'>
                            <div className='d-flex-column gap-1'>
                                <span className='normal'>Total from <span className='fw-semi-bold'>{formatCurrency(1000)}</span> </span>
                                <span className='small text-muted'>40 events</span>
                            </div>
                            <Button size="lg" className='mt-0 btn-next' onClick={showSeatmap}>Choose your seats</Button>
                        </div>
                    </Stack>
                </Card>
            </Col>
        </Row>
    );
}
